/* eslint-disable no-nested-ternary */
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';

import Purchase from 'components/pages/sign-up/purchase';
import * as sessionParams from 'constants/sessionStorage';
import MainLayout from 'layouts/main';
import { getParam } from 'utils/api';
import { stripeKey } from 'utils/keys';

const cloudUnitPriceMap = {
  '180k': {
    price: 200,
    units: 180000,
    id: 'oneHundredEightyThousand',
  },
  '500k': {
    price: 500,
    units: 500000,
    id: 'fiveHundredThousand',
  },
};

const stripePromise = loadStripe(stripeKey);

const SignUpPage = () => {
  const [cloudUnitTier, setCloudUnitTier] = useState('');
  const [email, setEmail] = useState('');
  const [hasErrors, setHasErrors] = useState(false);

  const amount = cloudUnitPriceMap[cloudUnitTier]
    ? cloudUnitPriceMap[cloudUnitTier].price
    : 'Unknown';
  const cloudUnitQuantity = cloudUnitPriceMap[cloudUnitTier]
    ? cloudUnitPriceMap[cloudUnitTier].units
    : 'Unknown';
  const cloudUnitPlanID = cloudUnitPriceMap[cloudUnitTier]
    ? cloudUnitPriceMap[cloudUnitTier].id
    : null;

  const priceSummary = `7 day trial, then $${amount}/month for ${cloudUnitQuantity.toLocaleString()} sessions`;
  const priceButton = `Start Trial`;

  useEffect(() => {
    setEmail(getParam(sessionParams.email));
    setCloudUnitTier(getParam(sessionParams.tier));

    const hasErrors = !email;
    setHasErrors(hasErrors);
  }, [email]);

  const purchase = {
    title: hasErrors ? `Oops, something's not right` : `Complete your Cloud Subscription purchase`,
    details: {
      title: hasErrors
        ? `See that Restart button? Click it and let's retry this!`
        : `Selected hosted plan: <strong>Cloud Subscription ${cloudUnitTier} Tier</strong>.<br />${priceSummary}.`,
    },
    sidebar: {
      items: [
        {
          title: 'Select Tier',
          isCompleted: true,
        },
        {
          title: 'Verify Email',
          isCompleted: true,
        },
        {
          title: 'Complete',
          isActive: true,
        },
      ],
    },
  };

  return (
    <MainLayout headerTheme="grey-5">
      <Elements stripe={stripePromise}>
        <Purchase
          {...purchase}
          amount={amount}
          plan={cloudUnitPlanID}
          email={email}
          hasErrors={hasErrors}
          sidebar={{
            items: purchase.sidebar.items,
            button1: {
              url: '/sign-up',
              title: 'Back',
            },
            button2: hasErrors
              ? {
                  url: '/sign-up',
                  title: 'Restart',
                }
              : {
                  url: '/',
                  title: priceButton,
                },
            showAgreementCheckbox: true,
          }}
        />
      </Elements>
    </MainLayout>
  );
};

export default SignUpPage;
