export default [
  {
    value: '',
    text: '',
    disabled: true,
  },
  {
    value: 'screenshots',
    text: 'Generate Screenshots',
  },
  {
    value: 'pdf',
    text: 'Generate PDFs',
  },
  {
    value: 'automation',
    text: 'Automate a workflow',
  },
  {
    value: 'scraping',
    text: 'Collect data',
  },
  {
    value: 'screencast',
    text: 'Screencasting or screen recording',
  },
  {
    value: 'testing',
    text: 'Test automation or quality assurance',
  },
  {
    value: 'other',
    text: 'Other',
  },
];
