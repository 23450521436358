/* eslint-disable react/prop-types */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/shared/button';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import Link from 'components/shared/link';
import { paddings } from 'constants/spacing';

import CheckBox from '../checkbox/images/check.inline.svg';

import Check from './images/check.inline.svg';

const SignUpLayout = ({ title, details, sidebar, children, className: additionalClassName }) => {
  const isFirstPage = sidebar.items[0]?.isActive;
  const [checked, setChecked] = React.useState(false);

  const AsideButtons = () => (
    <>
      <div className="flex mt-10 space-x-5 md:mt-5 md:space-x-3 sm:flex-col sm:space-x-0 sm:space-y-3">
        {!isFirstPage && (
          <Button
            className="w-full !p-0"
            to={sidebar.button1.url}
            size="md"
            theme="primary-black-outline"
            disabled={sidebar.button1.disabled || sidebar.button1.loading}
          >
            {sidebar.button1.title}
          </Button>
        )}
        <Button
          className="w-full !p-0"
          to={sidebar.button2.url}
          size="md"
          theme="primary-black-filled"
          disabled={sidebar.button2.disabled || sidebar.button2.loading || !checked}
          onClick={sidebar.button2.onClick}
        >
          {sidebar.button2.loading ? (
            <div className="border-b-2 rounded-full animate-spin h-7 w-7 border-white-900" />
          ) : (
            sidebar.button2.title
          )}
        </Button>
      </div>
      {sidebar.showAgreementCheckbox && (
        <>
          <label className={classNames('inline-flex cursor-pointer mt-6 text-base')}>
            <input
              className="sr-only peer"
              type="checkbox"
              checked={checked}
              onClick={() => {
                setChecked(!checked);
              }}
            />
            <span
              className={classNames(
                'relative shrink-0 w-5 h-5 border transition-colors duration-200',
                checked && 'bg-secondary-blue border-secondary-blue'
              )}
            >
              <CheckBox className="absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2" />
            </span>
            <span className="text-sm leading-normal with-link-primary-deep-blue ml-2.5">
              I have read and agree to our{' '}
              <a href="/terms-of-service" target="_blank">
                Terms of Service
              </a>{' '}
              and{' '}
              <a href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>{' '}
            </span>
          </label>
        </>
      )}
    </>
  );

  return (
    <section
      className={classNames('grow h-full pt-20 xl:pt-15 md:pt-10 bg-grey-5', paddings.bottom.lg)}
    >
      <Container>
        {title && (
          <Heading className="text-center" tag="h1" size="xl" theme="primary-black">
            {title}
          </Heading>
        )}
        <div className="flex items-start space-x-8 mt-14 xl:space-x-7 lg:space-x-0 lg:space-y-4 lg:flex-col lg:items-stretch lg:mt-10">
          <div
            className={classNames(
              additionalClassName ||
                'w-full px-8 pt-8 pb-6 border border-grey-10 bg-primary-white md:p-4',
              !details.link1 && 'pb-10'
            )}
          >
            {details.title && (
              <Heading
                className="with-highlighted-text-secondary-pink"
                tag="h3"
                size="sm"
                theme="primary-black"
                asHTML
              >
                {details.title}
              </Heading>
            )}
            {details.description && (
              <p className="mt-3" dangerouslySetInnerHTML={{ __html: details.description }} />
            )}
            {children}
            {details.link1 && (
              <div className="flex space-x-8 border-t border-t-grey-10 mt-15 md:mt-7 md:pt-5 sm:flex-col sm:space-x-0 sm:space-y-4">
                <Link to={details.link1.url} size="sm" theme="primary-deep-blue">
                  {details.link1.title}
                </Link>
              </div>
            )}
          </div>
          <div className="w-[384px] shrink-0 xl:w-[350px] lg:w-full top-5 lg:top-0 lg:relative md:p-4 flex flex-col">
            <div className="lg:order-last">
              {isFirstPage && (
                <span className="flex items-center justify-center h-10 text-sm font-black tracking-wide uppercase rounded-t text-primary-black mb-0 bg-browserless-gradient">
                  Looking for something else?
                </span>
              )}
              <div className="p-8 border border-grey-10 bg-primary-white">
                {isFirstPage ? (
                  <div>
                    <div>
                      <p className="text-lg text-justify ![hyphens:auto]" lang="en-US">
                        We offer Dedicated Instances, Self-Hosted Subscriptions, and our Premium
                        Build in addition to Unit-Based plans. Are you interested?
                      </p>

                      <div className="flex gap-1 lg:gap-0 lg:flex-col">
                        <Button
                          className="w-full !p-0 mt-5 !border-[1px] font-medium"
                          to="/pricing-faq"
                          size="md"
                          theme="primary-black-outline"
                        >
                          Learn More
                        </Button>
                        <Button
                          className="w-full !p-0 mt-5 !border-[1px] font-medium"
                          to="/contact/"
                          size="md"
                          theme="primary-black-outline"
                        >
                          Contact Sales
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <ol className="space-y-8 md:space-y-6">
                    {sidebar.items.map(({ title, isActive, isCompleted }, index) => {
                      const lineClassName =
                        index !== sidebar.items.length - 1
                          ? 'relative after:absolute after:left-1/2 after:bottom-[-3px] after:translate-y-full after:-translate-x-1/2 after:w-0.5 after:h-[26px] md:after:h-[18px] after:bg-grey-5'
                          : null;

                      return (
                        <li className="flex items-center space-x-3 text-lg font-bold" key={index}>
                          <div className={lineClassName}>
                            {isCompleted ? (
                              <Check />
                            ) : (
                              <span
                                className={classNames(
                                  'flex items-center justify-center w-10 h-10 rounded-full',
                                  isActive ? 'bg-primary-black text-primary-white' : 'bg-grey-5'
                                )}
                              >
                                {index + 1}
                              </span>
                            )}
                          </div>
                          <span>{title}</span>
                        </li>
                      );
                    })}
                  </ol>
                )}
                {!isFirstPage && <AsideButtons />}
              </div>
            </div>
            {isFirstPage && <AsideButtons />}
          </div>
        </div>
      </Container>
    </section>
  );
};

const sharedPropTypes = {
  title: PropTypes.string.isRequired,
  details: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    link1: PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  }).isRequired,
  sidebar: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        isActive: PropTypes.bool,
        isCompleted: PropTypes.bool,
      })
    ).isRequired,
    button1: PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    button2: PropTypes.shape({
      url: PropTypes.string,
      onClick: PropTypes.func,
      title: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      loading: PropTypes.bool,
    }).isRequired,
    showAgreementCheckbox: PropTypes.bool,
    onAgreementChange: PropTypes.func,
  }).isRequired,
};

SignUpLayout.propTypes = {
  ...sharedPropTypes,
  children: PropTypes.node.isRequired,
};

export const signUpLayoutPropTypes = sharedPropTypes;

export default SignUpLayout;
