/* eslint-disable import/prefer-default-export */
const isProd = process.env.NODE_ENV === 'production';

export const recaptchaKey = () =>
  isProd ? '6LeWjSMUAAAAAINsA_bTBDC-P8deCpjypfTzsPzc' : '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

export const stripeKey = isProd
  ? 'pk_live_MYFM7DcEcLFy4iO2U4mtoNyO'
  : 'pk_test_TV0wsbqSdaKuzX7JFWeUsGgw';

export const formSubmitEvent = isProd ? 'form-submit-complete' : 'dev-form-submit-complete';
export const signUpEvent = isProd ? 'signup-complete' : 'dev-signup-complete';
export const verificationMailEvent = isProd ? 'verification-mail' : 'dev-verification-mail';
