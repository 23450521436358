/* global gtag, clearbitSlack */

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import classNames from 'classnames';
import noop from 'lodash.noop';
import React, { useState } from 'react';

import Field from 'components/shared/field';
import Asterisk from 'components/shared/field/images/asterisk.inline.svg';
import SignUpLayout, {
  signUpLayoutPropTypes,
} from 'components/shared/sign-up-layout/sign-up-layout';
import * as sessionParams from 'constants/sessionStorage';
import useCases from 'constants/use-cases';
import { signupCloudUnits, cloudUrl, setParam } from 'utils/api';
import { signUpEvent } from 'utils/keys';
import { unwrapDOMEvent } from 'utils/utils';

import Arrow from './images/arrow.inline.svg';

// eslint-disable-next-line react/prop-types
const Required = ({ label }) => (
  <div className="flex gap-1">
    {label}
    <Asterisk className={classNames('mt-1', 'text-secondary-pink')} />
  </div>
);

const unwrapStripeEvent = (setState) => (evt) => setState(evt.complete);

const Purchase = (props) => {
  const {
    hasErrors,
    email,
    plan,
    sidebar,
    sidebar: { button2 },
  } = props;

  const stripe = useStripe();
  const elements = useElements();

  const [isAdvancedDataDropdownToggled, setIsAdvancedDataDropdownToggled] = useState(false);
  const [fullName, setFullName] = useState('');
  const [company, setCompany] = useState('');
  const [password, setPassword] = useState('');
  const [useCase, setStateUseCase] = useState(useCases[0].value);
  const [line1, setAddressOne] = useState('');
  const [line2, setAddressTwo] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [taxId, setTaxId] = useState('');
  const [error, setError] = useState('');
  const [isBusy, setIsBusy] = useState(false);

  const [cardIsValid, setCardIsValid] = useState(false);
  const [expiryIsValid, setExpiryIsValid] = useState(false);
  const [cvcIsValid, setCvcIsValid] = useState(false);

  const setUseCase = (useCase) => {
    setParam(sessionParams.useCase, useCase);
    setStateUseCase(useCase);
  };

  const onSetFullName = unwrapDOMEvent(setFullName);
  const onSetCompany = unwrapDOMEvent(setCompany);
  const onSetPassword = unwrapDOMEvent(setPassword);
  const onSetAddressOne = unwrapDOMEvent(setAddressOne);
  const onSetAddressTwo = unwrapDOMEvent(setAddressTwo);
  const onSetCity = unwrapDOMEvent(setCity);
  const onSetState = unwrapDOMEvent(setState);
  const onSetCountry = unwrapDOMEvent(setCountry);
  const onSetZip = unwrapDOMEvent(setPostalCode);
  const onSetTaxId = unwrapDOMEvent(setTaxId);

  const onSetUseCase = (evt) => {
    const { value } = evt.target;
    setUseCase(value);
  };

  const handleAdvancedDataDropdownClick = () => {
    setIsAdvancedDataDropdownToggled(!isAdvancedDataDropdownToggled);
  };

  const cardOptions = {
    style: {
      base: {
        fontSize: '20px',
        color: '#0d0d0d',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#cccccc',
        },
      },
      invalid: {
        color: '#ee0004',
      },
    },
  };

  const handleSubmit = async (event, completedActionId = undefined) => {
    event.preventDefault();
    setError('');
    setIsBusy(true);

    if (!stripe || !elements) {
      setError('Please wait a few seconds for us to catch up.');
      setIsBusy(false);
      return;
    }

    const payload = await stripe.createToken(elements.getElement(CardNumberElement));

    if (payload.error) {
      setError(payload.error.message);
      setIsBusy(false);
      return;
    }

    if (!payload.token) {
      setError(`We couldn't process your payment from Stripe, please try again later`);
      setIsBusy(false);
      return;
    }

    const stripeToken = payload.token.id;
    const signUpPromise = signupCloudUnits({
      fullName,
      company,
      useCase,
      email,
      plan,
      stripeToken,
      password,
      address: {
        line1,
        line2,
        postalCode,
        country,
        state,
        city,
        taxId,
      },
      completedActionId,
    });

    try {
      const response = await signUpPromise;
      const signUpResponse = response?.signupCloudUnits || response?.signup;
      if (signUpResponse?.requiresAction) {
        const stripeAuth = await stripe.confirmSetup({
          clientSecret: signUpResponse?.requiresAction,
          redirect: 'if_required',
        });
        if (stripeAuth.error) throw new Error(stripeAuth.error.message);
        if (stripeAuth.setupIntent?.status === 'succeeded')
          handleSubmit(event, signUpResponse?.requiresAction);
        else
          throw new Error(
            'We are unable to authenticate your payment method. Please choose a different payment method and try again.'
          );
      } else {
        setIsBusy(false);
        try {
          // Data-gathering stuff

          gtag('event', signUpEvent, { data: email });
          clearbitSlack.notify({ email });
        } catch {
          noop();
        }
        window.location.href = cloudUrl;
      }
    } catch (err) {
      setIsBusy(false);
      setError(err.message);
    }
  };

  const signupLayoutProps = {
    ...props,
    sidebar: {
      ...sidebar,
      button2: {
        ...button2,
        disabled:
          !hasErrors &&
          (!password ||
            !fullName ||
            !useCase ||
            !cardIsValid ||
            !expiryIsValid ||
            !cvcIsValid),
        loading: isBusy,
        onClick: hasErrors ? undefined : handleSubmit,
      },
      onAgreementChange: noop,
    },
  };

  return (
    <SignUpLayout {...signupLayoutProps}>
      {!hasErrors && (
        <div className="mt-8 space-y-8 sm:space-y-4">
          {error && (
            <div className="px-4 py-3 text-red-700 bg-red-100 border border-red-400" role="alert">
              <span className="block sm:inline">{error}</span>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="flex mb-4 space-x-5 sm:flex-col sm:space-x-0 sm:space-y-4">
              <label className="w-full sm:w-auto">
                <Required label="Card number" />
                <CardNumberElement
                  className="h-12 p-3 px-4 text-base transition-colors duration-200 border border-black outline-none bg-primary-white"
                  options={cardOptions}
                  onChange={unwrapStripeEvent(setCardIsValid)}
                />
              </label>
              <label className="w-64 sm:w-auto">
                <Required label="Expiration date" />
                <CardExpiryElement
                  className="h-12 p-3 px-4 text-base transition-colors duration-200 border border-black outline-none bg-primary-white"
                  options={cardOptions}
                  onChange={unwrapStripeEvent(setExpiryIsValid)}
                />
              </label>
              <label className="w-36 sm:w-auto">
                <Required label="CVC" />
                <CardCvcElement
                  className="h-12 p-3 px-4 text-base transition-colors duration-200 border border-black outline-none bg-primary-white"
                  options={cardOptions}
                  onChange={unwrapStripeEvent(setCvcIsValid)}
                />
              </label>
            </div>

            <div className="flex mb-4 space-x-5 sm:flex-col sm:space-x-0 sm:space-y-4">
              <Field
                autoComplete="full-name"
                className="w-full"
                id="full-name"
                label="Full Name"
                fieldType="input"
                type="text"
                style={{ font: 'small-caption' }}
                value={fullName}
                maxlength={80}
                isRequired
                onChange={onSetFullName}
              />
              <Field
                autoComplete="company"
                className="w-full"
                id="company"
                label="Company"
                fieldType="input"
                type="text"
                style={{ font: 'small-caption' }}
                value={company}
                onChange={onSetCompany}
              />
            </div>
            <div className="flex mb-4 space-x-5 sm:flex-col sm:space-x-0 sm:space-y-4">
              <Field
                autoComplete="current-password"
                className="w-full"
                id="password"
                label="Password"
                fieldType="input"
                type="password"
                value={password}
                style={{ font: 'small-caption' }}
                isRequired
                onChange={onSetPassword}
              />
            </div>
            <div className="flex mb-4 space-x-5 sm:flex-col sm:space-x-0 sm:space-y-4">
              <Field
                autoComplete="usage-type"
                className="w-full"
                id="usage-type"
                label="Usage Type"
                fieldType="select"
                options={useCases}
                style={{ font: 'small-caption' }}
                value={useCase}
                isRequired
                onChange={onSetUseCase}
              />
            </div>
            <div
              className="flex items-center outline-none hover:text-grey-70"
              role="button"
              tabIndex="0"
              onClick={handleAdvancedDataDropdownClick}
              onKeyPress={handleAdvancedDataDropdownClick}
            >
              <span className="text-sm font-semibold transition-colors duration-200">
                Show advanced data
              </span>{' '}
              <Arrow
                className={classNames(
                  'ml-2 transition-all duration-200',
                  isAdvancedDataDropdownToggled && 'rotate-180'
                )}
              />
            </div>
            {isAdvancedDataDropdownToggled && (
              <>
                <div className="flex mt-4 space-x-5 sm:flex-col sm:space-x-0 sm:space-y-4">
                  <Field
                    className="w-full"
                    id="address-line-one"
                    label="Address Line One"
                    fieldType="input"
                    type="text"
                    value={line1}
                    onChange={onSetAddressOne}
                  />
                  <Field
                    className="w-full"
                    id="address-line-two"
                    label="Address Line Two"
                    fieldType="input"
                    type="text"
                    value={line2}
                    onChange={onSetAddressTwo}
                  />
                </div>
                <div className="flex mt-4 space-x-5 sm:flex-col sm:space-x-0 sm:space-y-4">
                  <Field
                    className="w-full"
                    id="town-or-city"
                    label="Town or City"
                    fieldType="input"
                    type="text"
                    value={city}
                    onChange={onSetCity}
                  />
                  <Field
                    className="w-full"
                    id="state-or-province"
                    label="State or Province"
                    fieldType="input"
                    type="text"
                    value={state}
                    onChange={onSetState}
                  />
                </div>
                <div className="flex mt-4 space-x-5 sm:flex-col sm:space-x-0 sm:space-y-4">
                  <Field
                    className="w-full"
                    id="country"
                    label="Country (US)"
                    fieldType="input"
                    type="text"
                    value={country}
                    onChange={onSetCountry}
                  />
                  <div className="flex w-full space-x-5 sm:flex-col sm:space-x-0 sm:space-y-4">
                    <Field
                      className="w-full"
                      id="postal-code"
                      label="Postal Code"
                      fieldType="input"
                      type="text"
                      value={postalCode}
                      onChange={onSetZip}
                    />
                    <Field
                      className="w-full"
                      id="tax-or-vat-id"
                      label="Tax or VAT ID"
                      fieldType="input"
                      type="text"
                      value={taxId}
                      onChange={onSetTaxId}
                    />
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
      )}
    </SignUpLayout>
  );
};

Purchase.propTypes = signUpLayoutPropTypes;

export default Purchase;
