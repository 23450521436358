import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Arrow from './images/arrow.inline.svg';

const Select = ({ className: additionalClassName, options, isInvalid, ...otherProps }) => (
  <div className={classNames('relative', additionalClassName)}>
    <select
      className={classNames(
        'flex w-full h-12 border text-base bg-primary-white pl-4 pr-10 outline-none appearance-none transition-colors duration-200 overflow-ellipsis overflow-hidden',
        isInvalid ? 'border-state-error' : 'border-grey-60'
      )}
      {...otherProps}
    >
      {options.map(({ value, text, disabled }) => (
        <option value={value} key={value} disabled={disabled}>
          {text}
        </option>
      ))}
    </select>

    <Arrow className="absolute -translate-y-1/2 pointer-events-none top-1/2 right-4" />
  </div>
);

Select.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      text: PropTypes.string.isRequired,
      disable: PropTypes.bool,
    })
  ).isRequired,
  isInvalid: PropTypes.bool,
};

Select.defaultProps = {
  className: null,
  isInvalid: false,
};

export default Select;
