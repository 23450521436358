import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Input from 'components/shared/input';
import Select from 'components/shared/select';

import Asterisk from './images/asterisk.inline.svg';

const Field = ({
  className: additionalClassName,
  id,
  label,
  fieldType,
  error,
  isRequired,
  isInvalid,
  ...otherProps
}) => {
  const Component = fieldType === 'input' ? Input : Select;

  return (
    <div className={classNames('relative', additionalClassName)}>
      <label
        className={classNames('flex text-sm leading-none', isInvalid && 'text-state-error')}
        htmlFor={id}
      >
        {label}
        {isRequired && (
          <Asterisk
            className={classNames('ml-1', isInvalid ? 'text-state-error' : 'text-secondary-pink')}
          />
        )}
      </label>
      <Component className="mt-2" id={id} isInvalid={isInvalid} {...otherProps} />
      {error && (
        <span className="absolute left-0 text-sm translate-y-full -bottom-2 text-state-error">
          {error}
        </span>
      )}
    </div>
  );
};

Field.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fieldType: PropTypes.oneOf(['input', 'select']).isRequired,
  error: PropTypes.string,
  isRequired: PropTypes.bool,
  isInvalid: PropTypes.bool,
};

Field.defaultProps = {
  className: null,
  error: null,
  isRequired: false,
  isInvalid: false,
};

export default Field;
